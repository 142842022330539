import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet";
import "@fortawesome/fontawesome-pro/css/all.css"
import ImageZoom from 'react-medium-image-zoom'

import Layout from '../../components/layout'
import Header from '../../components/header'
import ReturnToTop from '../../components/returnToTop';
import JumpLinks from '../../components/portfolioJumpLink'

import ImageSketch1 from '../../images/flux/flux-sketch-1.jpg';
import ImageSketch2 from '../../images/flux/flux-sketch-2.jpg';
import ImageSketch3 from '../../images/flux/flux-sketch-3.jpg';
 
import ImageRoadmap from '../../images/flux/flux-roadmap.png';
import ImageAudit from '../../images/flux/flux-ux-audit.jpg';
import ImageAB from '../../images/flux/flux-ab-testing.jpg';
import ImageIAhome from '../../images/flux/flux-ia-homenav.jpg';
import ImageIAentity from '../../images/flux/flux-ia-entity.jpg';
import ImagePersona1 from '../../images/flux/flux-persona-1.png';
import ImagePersona2 from '../../images/flux/flux-persona-2.png';
import ImageTaskAnalysis from '../../images/flux/flux-task-analysis.jpg';

import ImageWireframe1 from '../../images/flux/flux-wireframe-1.png';
import ImageWireframe2  from '../../images/flux/flux-wireframe-2.png';
import ImageWireframe3  from '../../images/flux/flux-wireframe-3.png';
import ImageWireframe4  from '../../images/flux/flux-wireframe-4.png';

import ImageMockup1 from '../../images/flux/flux-hifi-1.png';
import ImageMockup2 from '../../images/flux/flux-hifi-2.png';
import ImageMockup3 from '../../images/flux/flux-hifi-3.png';
import ImageMockup4 from '../../images/flux/flux-hifi-4.jpg';




const Portfolio1 = ({data}) => (
  <Layout>
      <main className="page--portfolio portfolio--flux">
      <Helmet>
          <title>{data.site.siteMetadata.fluxTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>
        <section className="slab slab--hero" name="hero">
        <Header siteTitle="Rob DiMarzo" /> 
          <div className="slab__inner">
            <div className="pageblock--title">
              <h1>
                {data.site.siteMetadata.fluxTitle}
              </h1>
              <span>
                {data.site.siteMetadata.fluxDesc}
              </span>
              <JumpLinks/>
            </div>
          </div>  
        </section>

        <section className="slab slab--notification">
          <div className="slab__inner">
            <p>
            Due to the sensitive and proprietary nature of this product, certain information has been withheld and modified. This product will be referred to as <strong>Flux</strong> and the client as <strong>Kappa</strong>.
            </p>
          </div>
        </section>
        
        <section className="slab slab--intro" name="intro">
          <div className="slab__overlay"></div>
          <div className="slab__inner">
            <div className="pageblock--title">
              <h2>Project Backstory</h2>
              <div className="title__line"></div>
            </div>
            <div className="pageblock">
              <div className="pageblock__content">
                <p>Flux is an industry-leading web application used by tax professionals to transform the financial records of many of the largest US investment funds into tax forms ready for filing with the IRS.</p>            
                <p>Since being launched in 2013, Flux's comprehensive features and user experience launched it ahead of competitors in the asset management space. Until 2016, Flux had been limited to only servicing partnerships (LLPs, GPs, LPs), but its success secured funding to expand the app to support more entity types, like REITs and RICs.</p> 

                <p>With this growth beyond partnerships, Flux had outgrown its original user experience. New modules and increasingly advanced features were added as the app grew to satisfy the complex needs of these new markets. This expansion further increased the complexity of the system, put stress on the UI, and fragmented the user experience.</p>                

                <p>To save Flux from its own success, it needed an end-to-end redesign. </p>
              </div>
            </div>

          </div>
        </section>
      
        <section className="slab slab--problem" name="goals-challenges">
          <div className="slab__inner">

            <div className="pageblock--title">  
                <h2>Identifying the goals & challenges</h2>
                <div className="title__line"></div>
                <p>
                  I conducted interviews with over 15 existing users and performed a full UX audit to understand the key issues to address in a redesign. 
                </p>
            </div>

            <div className="pageblock">
                <i className="fal fa-sitemap"></i>
                <h3>
                  Inaccurate Information Architecture
                </h3>
                <div className="pageblock__content">
                  <p>
                    With the introduction of new entity types, features, and pages, the underlying information architecture became inconsistent, unorganized, and out-of-sync with users' expectations of the business process.
                  </p>
                </div>
            </div>
            
            <div className="pageblock">
              <i className="fal fa-compass"></i>
              <h3>
                Unusable Navigation
              </h3>
              <div className="pageblock__content">
                <p>
                  The original navigation had many issues:
                </p>
                <ul>
                  <li>
                  Flux followed an unconventional tag-based navigation pattern that was overly-complex and unfamiliar to users. 
                  </li>
                  <li>
                  The UI component for navigation was not designed with scalability in mind, resulting in a very linear, inflexible, cumbersome, and broken navigation experience.  
                  </li>
                  <li>
                  Many areas within the app lacked meaningful navigation to contextually connect relevant items and pages. 
                  </li>
                </ul>
              </div>
            </div>

            <div className="pageblock">
              <i className="fal fa-search"></i>
              <h3>
                Difficult Findability
              </h3>
              <div className="pageblock__content">
                <p>
                In an application with an ever-increasing amount of records, users were struggling to find what they needed. Search results were unorganized, uninsightful, and difficult to distinguish, even when users entered the right criteria.
                </p>
              </div>
            </div>

            <div className="pageblock">
              <i className="fal fa-home"></i>
              <h3>
                Ineffective Homepage
              </h3>
              <div className="pageblock__content">
                <p>
                The old homepage was a bottleneck that lacked a clear content strategy, resulting in a page with an overwhelming amount of content, yet not a lot of substance. Users were often lost without a clear focus of what to do or where to go. Every user I studied would bounce through it as quickly as they could. 
                </p>
              </div>  
            </div>

            <div className="pageblock">
              <i className="fal fa-palette"></i>
              <h3>
                Outdated Visual Design
              </h3>
              <div className="pageblock__content">
                <p>
                  In the years since its launch, web design trends had shifted away from Flux's original skeuomorphic theme. The Firm had also recently updated its global design guidelines with new colors, fonts, and principles to be included in a UI redesign. 
                </p>
              </div>
            </div>
            
            <div className="pageblock">
              <i className="fal fa-bullseye-pointer"></i>
              <h3>
                Gaps in UX Quality
              </h3>
              <div className="pageblock__content">
                <p>
                  Many accessibility and usability concerns were not addressed during the development of the application, like proper HTML semantics, missing ARIA roles, keyboard access, load indication, deep linking, state retention, validation, and language consistency. 
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="slab slab--solution" name="my-role" >
          <div className="slab__inner">
            <div className="pageblock--title">
                <h2>My role in the product</h2>
                <div className="title__line"></div>
                <div className="pageblock__content">
                  <p>
                    I was the <strong>Lead UX Researcher, UI Designer, and UX Engineer </strong> on this project, providing end-to-end UX services - from strategy and research, to design and development.  I worked alongside 15 Users, 2 Full-stack Developers, 1 Junior UX Designer, 1 QA, and stakeholders from 8 teams throughout this process. I was personally responsible for the following:
                  </p>
                </div>
            </div>

            <div className="pageblock">
              <i className="fal fa-briefcase"></i>
              <h3>
                Product Strategy
              </h3>
              <div className="pageblock__content">
                <p>
                  I initiated the discussion and persuaded financial stakeholders to commit to a redesign. I did this through a series of pitches where I shared the scope of the problems, recommended actions, proposed deadlines, and provided valuation through ROI estimation.
                </p>
                <p>
                  Throughout the process, I communicated the vision and coordinated deliverables across several dependent project teams.
                </p>
              </div>
            </div>

            <figure className="pageblock--image">
              <ImageZoom
                image={{
                  src: ImageRoadmap,
                  alt: 'Roadmap of Redesign work streams',
                  className: 'img'
                }}
                zoomImage={{
                  src: ImageRoadmap,
                  alt: 'Roadmap of Redesign work streams',
                  className: 'img--zoomed'
                }}
              />
              <figcaption>
                A recommended timeline of UI/UX work streams that I used in early proposal discussions.
              </figcaption>
            </figure>


            <div className="pageblock">
                <i className="fal fa-clipboard-list"></i>
                <h3>
                  UX Audit
                </h3>
                <div className="pageblock__content">
                  <p>
                    I performed an end-to-end UX analysis of the application to identify inconsistencies and gaps in the UI design, interactions, front-end code, usability, and accessibility. My findings were documented, shared with business analysts, and turned into backlog requirements. 
                  </p>
                </div>
            </div>



            <figure className="pageblock--image is--half"> 
              <ImageZoom
                image={{
                  src: ImageAudit,
                  alt: 'Sample findings from UX audit',
                  className: 'img'
                }}
                zoomImage={{
                  src: ImageAudit,
                  alt: 'Sample findings from UX audit',
                  className: 'img--zoomed'
                }}
              />
 
              <figcaption>
                An isolated screenshot of the UX Audit, covering topics like HTML semantics, keyboard accessibility, missing load indications, poorly written copy, etc on every page and action in the app.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageIAhome,
                    alt: 'Before/after comparison of homepage IA',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageIAhome,
                    alt: 'Before/after comparison of homepage IA',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A diagram I used to communicate the linearity of the current Flux navigation vs a rearchitected structure allowing users to easily navigate anywhere. 
              </figcaption>
            </figure>
            
            <div className="pageblock">
              <i className="fal fa-user-chart"></i>
              <h3>
                User Research
              </h3>
              <div className="pageblock__content">
                <p>
                  I interviewed and observed existing users to understand and validate their business process, needs, and frictions. The insights were captured through user journey maps, task analysis documents, and personas. 
                </p>
              </div>
            </div>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImagePersona1,
                    alt: 'Persona for Tax Staff',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImagePersona1,
                    alt: 'Persona for Tax Staff',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A persona summarizing the Tax Staff, the largest group of day-to-day users of Flux.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImagePersona2,
                    alt: 'Persona for Tax Manager',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImagePersona2,
                    alt: 'Persona for Tax Manager',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A persona summarizing Tax Managers, the second-largest group of day-to-day users of Flux.
              </figcaption>
            </figure>


            <div className="pageblock">
              <i className="fal fa-sitemap"></i>
              <h3>
                Information Architecture
              </h3>
              <div className="pageblock__content">
                <p>
                  I facilitated card sorting activities to validate a common mental model of how users organize the application’s content into meaningful taxonomies. The findings were used to restructure the navigation, rename ambiguous content, and provide more sustainable relationships between pages. 
                </p>
              </div>
            </div>

            
            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageTaskAnalysis,
                    alt: 'Notes from Task Analysis',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageTaskAnalysis,
                    alt: 'Notes from Task Analysis',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A snapshot from a Task Analysis session, where users listed what they would want to accomplish within Flux. The tasks are then prioritized by importance and frequency of use.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageIAentity,
                    alt: 'Entity Navigation IA',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageIAentity,
                    alt: 'Entity Navigation IA',
                    className: 'img--zoomed'
                  }}
                />

              <figcaption>
                A sitemap organizing the navigation by entity type and creating new taxonomies that had not existed previously. 
              </figcaption>
            </figure>



            <div className="pageblock">
              <i className="fal fa-object-group"></i>
              <h3>
                Wireframes & Prototyping
              </h3>
              <div className="pageblock__content">
                <p>
                  I designed a variety of wireframes and mockups through several iterations of feedback with users and created interactive prototypes to demonstrate the user journey and implement controlled A/B testing. 
                </p>
                <p>
                  Since the existing app was heavily reliant on data tables to present information, many of the users I spoke to had a hard time picturing the content any other way. To demonstrate the art of the possible, I presented a variety of design concepts featuring cards, lists, and data visualizations, which all received positive reactions. While presenting these ideas, I encouraged users to think outside-the-box and share their ideas on how to represent meaningful information. I tested these insights through continuous design iteration and user testing, leading Flux toward a new UI finely curated to the content and navigation most beneficial to users. 
                </p>
                <p><u>Tools used for design:</u></p> 
                  <ul className="content__skills">
                    <li>Whiteboards, Pen & Paper</li>
                    <li>Balsamiq</li>
                    <li>Axure</li>
                    <li>Sketch</li>
                    <li>Invision</li>
                    <li>HTML, CSS, JavaScript</li>
                  </ul>
              </div>
            </div>

            <figure className="pageblock--image is--third"> 
              <ImageZoom
                  image={{
                    src: ImageSketch1,
                    alt: 'Early sketch of Flux',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageSketch1,
                    alt: 'Early sketch of Flux',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                An early sketch to help orient the scope and relationship of task data.
              </figcaption>
            </figure>
            
            <figure className="pageblock--image is--third">
              <ImageZoom
                  image={{
                    src: ImageSketch2,
                    alt: 'Early sketch of Flux',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageSketch2,
                    alt: 'Early sketch of Flux',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                An early sketch for a card-based homepage.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--third"> 
              <ImageZoom
                  image={{
                    src: ImageSketch3,
                    alt: 'Early sketch of Flux',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageSketch3,
                    alt: 'Early sketch of Flux',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A sketch used to plan the differences in layout and behavior between the homepage and pages within the application.
              </figcaption>
            </figure>


            <figure className="pageblock--image is--half"> 
                <ImageZoom
                  image={{
                    src: ImageWireframe1,
                    alt: 'Early wireframe of Flux',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageWireframe1,
                    alt: 'Early wireframe of Flux',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A wireframe exposing navigation into all current year projects and workpapers.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
                <ImageZoom
                  image={{
                    src: ImageWireframe2,
                    alt: 'Early wireframe of Flux',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageWireframe2,
                    alt: 'Early wireframe of Flux',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A wireframe consolidating navigation and presenting overview of the engagement.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half">  
              <ImageZoom
                  image={{
                    src: ImageWireframe3,
                    alt: 'Early wireframe of Flux',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageWireframe3,
                    alt: 'Early wireframe of Flux',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A wireframe presenting workpapers as color-coded cards with an overview shelf.
              </figcaption>
            </figure>
            

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageWireframe4,
                    alt: 'Early wireframe of Flux',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageWireframe4,
                    alt: 'Early wireframe of Flux',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A wireframe presenting a larger overview area with alternatively positioned navigation.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageAB,
                    alt: 'Results of AB testing wireframes',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageAB,
                    alt: 'Results of AB testing wireframes',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                Screenshot of results from A/B testing 30 users' preferences of 4 different UI approaches. This is <u>not related</u> to the mockups on this portfolio page. 
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
                <ImageZoom
                  image={{
                    src: ImageMockup1,
                    alt: 'Mockup of Flux - Client Overview',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageMockup1,
                    alt: 'Mockup of Flux - Client Overview',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A mockup of Client Overview, providing visualization and navigation into the projects and workpapers within the engagement.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
                <ImageZoom
                  image={{
                    src: ImageMockup2,
                    alt: 'Mockup of Flux - Workpaper Tasks',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageMockup2,
                    alt: 'Mockup of Flux - Workpaper Tasks',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A mockup of Workpaper Tasks, providing actionable insights into the workpaper's sign offs, assignments, and distribution of work activity.
              </figcaption>
            </figure>

          <div className="pageblock">
                <i className="fal fa-code"></i>
                <h3>
                  UX Engineering                
                </h3>
                <div className="pageblock__content">
                  <p>
                    I utilized the best practices of HTML, CSS, and accessibility to develop new responsive layouts and components to support the newly designed areas of the application. 
                  </p>
                  <p>
                     I engineered the interaction design by implementing custom behaviors and animations to enhance the fluidity and quality of the user experience. 
                  </p>
                  <p>
                    After a thorough analysis of legacy rule-sets, I removed thousands of lines of CSS, and refactored the existing stylesheet using SCSS.
                  </p>
                  <p><u>Skills used for development:</u></p> 
                  <ul className="content__skills">
                    <li>HTML</li>
                    <li>CSS (SCSS)</li>
                    <li>jQuery</li>
                    <li>WAI-ARIA</li>
                  </ul>
                </div>
            </div>

            <figure className="pageblock--image is--full"> 
                <ImageZoom
                  image={{
                    src: ImageMockup4,
                    alt: 'Mockup of Flux - Workpaper Megamenu',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageMockup4,
                    alt: 'Mockup of Flux - Workpaper Megamenu',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                Flux's mega menus were newly designed to overcome the previous unusable, disorganized, and cumbersome navigation menus. They are also fully accessible to keyboards and screenreaders.
              </figcaption>
            </figure>

             <figure className="pageblock--image is--full"> 
                <ImageZoom
                  image={{
                    src: ImageMockup3,
                    alt: 'Mockup of Flux - Workpaper Overview',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageMockup3,
                    alt: 'Mockup of Flux - Workpaper Overview',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                Flux's Workpaper Overview page provides actionable insights into the most recent book-to-tax calculations, current tax activity of underlying investments, and state filing activity. 
              </figcaption>
            </figure>



            




            <div className="pageblock">
              <i className="fal fa-stars"></i>
              <h3>
                Identity Design
              </h3>
              <div className="pageblock__content">
                <p>
                  I aligned the UI with the firm's new global branding guidelines through the use of branded colors and typography. The new UI theme was a complete overhaul of the existing application, resulting in a clean interface that prioritizes content, state, and behavior over decoration. I also designed a new logo that symbolizes the narrative of the application and the business it serves.
                </p>
              </div>
            </div>



          </div>
        </section>  
    </main>
    <ReturnToTop/>
  </Layout>
)

export const query = graphql`
  query FluxQuery {
    site {
      siteMetadata {
        title
        fluxTitle
        fluxDesc
      }
    }
  }
`
export default Portfolio1
